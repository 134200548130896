import { takeLatest, call, put } from "redux-saga/effects";
import { pathObj } from "../../services/apiPath";
import { apiDelete, apiGet, apiPost } from "services/httpServices";
import { successToast } from "utils/toastMessage";
import { Window } from "@mui/icons-material";
import { trackEvent } from "utils/analytics";

function* handelLogin(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(apiPost, pathObj.LOGIN, action.payload);
    if (response.status == 200) {
      // successToast("You are successfully logged in");
      callback && callback(false, false);
      localStorage.setItem("access_token", response?.data?.token);
      //******* GOOGLE ANALYTICS *********/
      // trackEvent("Login", "login_successful", response?.data || "N/A");
      trackEvent("login_successful", {
        page: "Login",
        data: response?.data || "N/A",
      });

      //******* GOOGLE ANALYTICS *********/
      yield put({
        type: "SET_USER_PROFILE",
        payload: response?.data?.user,
      });
      yield put({ type: "SET_LOGIN", payload: response?.data });
    } else {
      callback && callback(false, true);

      //******* GOOGLE ANALYTICS *********/
      // trackEvent("Login", "login_failed", {
      //   status: response.status || "N/A",
      //   message: "Unexpected response",
      // });
      trackEvent("login_failed", {
        page: "Login",
        status: response.status || "N/A",
        message: "Unexpected response",
      });
      //******* GOOGLE ANALYTICS *********/

      console.log("Something went wrong");
    }
  } catch (error) {
    //******* GOOGLE ANALYTICS *********/
    // trackEvent("Login", "login_failed", "N/A", error);
    trackEvent("login_failed", {
      error_data: error || "Unknown error",
    });
    //******* GOOGLE ANALYTICS *********/
    callback(false, true, error);
    console.log(error);
  }
}
function* handelForgotPassword(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(
      apiPost,
      pathObj.FORGOT_PASSWORD,
      action.payload
    );
    if (response.status == 200) {
      // successToast(response.data.message);
      callback && callback(false, false);
      yield put({ type: "SET_FORGOT_PASSWORD", payload: action.payload });
    } else {
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback(false, true, error);
    console.log(error);
  }
}

function* handelOtpVerify(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(apiPost, pathObj.OTP_VERIFY, action.payload);
    if (response.status == 200) {
      // successToast(response.data.message);
      callback && callback(false, false);
      yield put({ type: "SET_OTP_VERIFY", payload: action.payload });
    } else {
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback(false, true, error);
    console.log(error);
  }
}

function* handelResetPassword(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(
      apiPost,
      pathObj.RESET_PASSWORD,
      action.payload
    );
    if (response.status == 200) {
      // successToast(response.data.message);
      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback(false, true);
    console.log(error);
  }
}
function* handelGenerateOtp(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(apiPost, pathObj.GENERATE_OTP, action.payload);
    if (response.status == 200) {
      // successToast(response.data.message);
      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback(false, true, error);
    console.log(error);
  }
}

function* handelVerifyUser(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(apiPost, pathObj.USER_VERIFY, action.payload);
    if (response.status == 200) {
      console.log("RESPONSE", response);
      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback(false, true, error);
    console.log(error);
  }
}

function* handelSignupOtpVerify(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(apiPost, pathObj.OTP_VERIFY, action.payload);
    if (response.status == 200) {
      // successToast(response.data.message);
      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback(false, true, error);
    console.log(error);
  }
}

function* handelRegister(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(apiPost, pathObj.REGISTER, action.payload);
    if (response.status == 200) {
      successToast(
        "Congratulations! You have successfully signed up for Food Dudes"
      );
      callback && callback(false, false);
      localStorage.setItem("access_token", response?.data?.token);
      yield put({ type: "SET_LOGIN", payload: response?.data });

      yield put({
        type: "SET_USER_PROFILE",
        payload: response?.data?.user,
      });
    } else {
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback(false, true);
    console.log(error);
  }
}

function* handelGuestRegister(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(
      apiPost,
      pathObj.GUEST_REGISTER,
      action.payload
    );
    if (response.status == 200) {
      /* successToast(
        "Congratulations! You have successfully signed up for Food Dudes"
      ); */
      callback && callback(false, false);
      sessionStorage.setItem("__g_access_token", response?.data?.token);
      yield put({ type: "SET_LOGIN", payload: response?.data });
    } else {
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback(false, true);
    console.log(error);
  }
}

function* handelMasterLogin(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(
      apiGet,
      pathObj.MASTER_LOGIN + action.payload?.masterToken
    );
    console.log("response", response);
    if (response.status == 200) {
      // successToast("You are successfully logged in");
      callback && callback(false, false);
      localStorage.setItem("access_token", response?.data?.token);
      yield put({ type: "SET_LOGIN", payload: response?.data });
    } else {
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback(false, true, error);
    console.log("err", error);
  }
}

// ***************RESTAURANT SIGN-UP***********************

function* handelRestaurantSignUp(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(
      apiPost,
      pathObj.RESTAURANT_SIGN_UP,
      action.payload
    );
    if (response.status == 200) {
      // successToast(response?.data?.message);
      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true, error);
    console.log(error);
  }
}

// ***************DRIVER SIGN-UP***********************
function* getCities(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(apiGet, pathObj.CITIES, action.payload);
    if (response.status == 200) {
      callback && callback(false, false);
      yield put({
        type: "SET_CITY_DATA",
        payload: response?.data?.data,
      });
    } else {
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true);
    console.log(error);
  }
}

function* handelDriverSignUp(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(
      apiPost,
      pathObj.DRIVER_SIGN_UP,
      action.payload
    );
    if (response.status == 200) {
      // successToast(response?.data?.message);
      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true);
    console.log(error);
  }
}
function* handelLogout(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(apiGet, pathObj.LOGOUT, action.payload);
    if (response.status == 200) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("myStore");

      //comment by ss
      yield put({
        type: "SET_LOGIN",
        payload: { other_info: {}, token: "" },
      });
      //comment by ss

      /* yield put({
        type: "SET_CART_LIST",
        payload: [],
      }); */

      //Comment by ss for react native logout feature
      /* yield put({
        type: "LOGOUT",
        payload: "",
      }); */
      //End of comment for react native logout feature
      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true);
    console.log(error);
  }
}

function* handelDeleteAccount(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(
      apiDelete,
      pathObj.DELETE_ACCOUNT,
      action.payload
    );
    if (response.status == 200) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("myStore");
      /* yield put({
        type: "SET_LOGIN",
        payload: { other_info: {}, token: "" },
      }); */

      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true);
    console.log(error);
  }
}

function* handelChangePassword(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(
      apiPost,
      pathObj.CHANGE_PASSWORD,
      action.payload
    );
    if (response.status == 200) {
      // successToast(response.data.message);
      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback(false, true, error);
    console.log(error);
  }
}
// ****************************** Social Signup ***************************************
function* handelVerifyProvider(action) {
  let { callback } = action;
  callback && callback(null, true, true);
  try {
    const response = yield call(
      apiPost,
      pathObj.VERIFY_PROVIDER,
      action.payload
    );
    if (response.status == 200) {
      console.log("RESPONSE Data", response);
      callback && callback(response?.data, false, false);
    } else {
      callback && callback(null, false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback(null, false, true);
    console.log(error);
  }
}

function* handelSocialLogin(action) {
  let { callback } = action;
  callback && callback(null, true, true);
  try {
    const response = yield call(apiPost, pathObj.SOCIAL_LOGIN, action.payload);
    if (response.status == 200) {
      // successToast(response.data.message);
      localStorage.setItem("access_token", response?.data?.token);
      yield put({ type: "SET_LOGIN", payload: response?.data });
      callback && callback(response?.data, false, false);
    } else {
      callback && callback(null, false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback(null, false, true);
    console.log(error);
  }
}

export function* AuthWatcher() {
  yield takeLatest("GET_LOGIN", handelLogin);
  yield takeLatest("GET_FORGOT_PASSWORD", handelForgotPassword);
  yield takeLatest("GET_OTP_VERIFY", handelOtpVerify);
  yield takeLatest("GET_RESET_PASSWORD", handelResetPassword);
  yield takeLatest("GET_GENERATE_OTP", handelGenerateOtp);
  yield takeLatest("GET_USER_VERIFY", handelVerifyUser);
  yield takeLatest("GET_SIGNUP_OTP_VERIFY", handelSignupOtpVerify);
  yield takeLatest("GET_REGISTER_USER", handelRegister);
  yield takeLatest("GET_REGISTER_GUEST_USER", handelGuestRegister);
  yield takeLatest("GET_RESTAURENT_SIGNUP", handelRestaurantSignUp);
  yield takeLatest("GET_CITY_DATA", getCities);
  yield takeLatest("GET_DRIVER_SIGN_UP", handelDriverSignUp);
  yield takeLatest("GET_LOGOUT", handelLogout);
  yield takeLatest("GET_CHANGE_PASSWORD", handelChangePassword);
  yield takeLatest("GET_CHECK_PROVIDER_ALREADY_EXIST", handelVerifyProvider);
  yield takeLatest("GET_SOCIAL_LOGIN", handelSocialLogin);
  yield takeLatest("GET_MASTER_LOGIN", handelMasterLogin);
  yield takeLatest("DELETE_ACCOUNT", handelDeleteAccount);
}
