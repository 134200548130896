import ReactGA from "react-ga4";

const GA_MEASUREMENT_ID = "G-0NJ1SCHQT9";

// export const initGA = () => {
//   ReactGA.initialize(GA_MEASUREMENT_ID, {
//     testMode: false, // Ensure it's not running in test mode
//     gaOptions: {
//       debug_mode: true, // Debug mode enabled
//     },
//   });

//   // Ensure gtag exists before setting debug mode
//   if (window.gtag) {
//     window.gtag("set", { debug_mode: true });
//   }

//   console.log("GA4 Initialized");
// };

export const initGA = () => {
  if (!GA_MEASUREMENT_ID) {
    console.warn("GA_MEASUREMENT_ID is missing!");
    return;
  }

  ReactGA.initialize(GA_MEASUREMENT_ID, {
    testMode: false,
    gaOptions: {
      debug_mode: true,
    },
  });

  console.log("GA4 Initialized");

  // Ensure gtag exists before setting debug mode
  if (window.gtag) {
    window.gtag("config", GA_MEASUREMENT_ID, {
      debug_mode: true,
      send_page_view: false, // Prevent automatic page views
    });
  }
};

// Track Page Views Correctly
// export const trackPageView = (path) => {
//   console.log("Tracking page view:", path);
//   // ReactGA.send("page_view", { page_location: window.location.href });
//   ReactGA.send("page_view", {
//     page_title: document.title,
//     page_location: window.location.href, // Required for GA4
//     page_path: path, // Ensure it's tracking the correct path
//   });
// };
export const trackPageView = (path) => {
  console.log("Tracking page view:", path);

  ReactGA.event("page_view", {
    page_title: document.title,
    page_location: window.location.href,
    page_path: path,
  });
};

/// Fix Event Tracking Format
export const trackEvent = (eventName, eventParams = {}) => {
  console.log("GA Event Sent:", eventName, eventParams);
  ReactGA.event(eventName, eventParams);
};
