import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  renderCheckboxRequiredOpt,
  renderDropdownTitleSecondLevel,
} from "helpers/AddonsModal/AddonsModalHocFunctions";
import { errorToast } from "utils/toastMessage";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";

const SecondLevelBlock = ({
  setVisibleSecondLevelBlock,
  secondLevelData,
  addonsObj,
  setAddonsObj,
  setTemp,
  temp,
  setErrorHandler,
  errorHandlerArr,
  RESTAURANTS,
  ADDONS_DETAILS,
}) => {
  //ALL STATE
  const divRefs = useRef([]);
  const handelChangeCheckbox = (
    event,
    firstLevelOptionValue,
    secondLevelOption,
    secondLevelOptionValue
  ) => {
    const result = {
      options_id: secondLevelOption?.options_id,
      options_values_id: secondLevelOptionValue?.options_values_id,
      options_values_price: secondLevelOptionValue?.options_values_price,
      // options_value_text: "",
      attributes_max_select: secondLevelOption?.attributes_max_select,
      attributes_min_select: secondLevelOption?.attributes_min_select,
      products_options_name: secondLevelOption?.products_options_name,
      products_options_values_name:
        secondLevelOptionValue?.products_options_values_name,
      quantity: secondLevelOption?.increment_choice_quantity,
      optionValues1: secondLevelOption?.optionValues,
      free_modifier_quantity: secondLevelOption?.free_modifier_quantity,
      attributes_default: secondLevelOptionValue?.attributes_default,
    };
    const newTemp = addonsObj?.attributes?.find(
      (ty) => ty?.options_values_id == firstLevelOptionValue?.options_values_id
    );
    if (event) {
      newTemp.second_level_attributes.push(result);
      setAddonsObj(addonsObj);
      setTemp(!temp);
    } else {
      newTemp.second_level_attributes = newTemp.second_level_attributes.filter(
        (el) =>
          el?.options_values_id !== secondLevelOptionValue.options_values_id
      );
      setAddonsObj(addonsObj);
      setTemp(!temp);
    }
  };

  const handelChangeRadioSecondLevel = (
    firstLevelOptionValue,
    event,
    secondLevelOption
  ) => {
    const secondLevelOptionValue = JSON.parse(event);
    const result = {
      options_id: secondLevelOption?.options_id,
      options_values_id: secondLevelOptionValue?.options_values_id,
      options_values_price: secondLevelOptionValue?.options_values_price,
      attributes_max_select: secondLevelOption?.attributes_max_select,
      attributes_min_select: secondLevelOption?.attributes_min_select,
      products_options_name: secondLevelOption?.products_options_name,
      products_options_values_name:
        secondLevelOptionValue?.products_options_values_name,
      quantity: secondLevelOptionValue?.quantity,
      optionValues1: secondLevelOption?.optionValues,
      free_modifier_quantity: secondLevelOption?.free_modifier_quantity,
      attributes_default: secondLevelOptionValue?.attributes_default,
    };
    const newTemp = addonsObj?.attributes?.find(
      (ty) => ty?.options_values_id == firstLevelOptionValue?.options_values_id
    );
    if (
      newTemp?.second_level_attributes?.some(
        (dl) => dl?.options_id == secondLevelOption?.options_id
      )
    ) {
      const pal = newTemp?.second_level_attributes?.filter(
        (el) => el?.options_id !== secondLevelOption?.options_id
      );
      pal.push(result);
      newTemp.second_level_attributes = pal;
      setAddonsObj(addonsObj);
      setTemp(!temp);
    } else {
      newTemp.second_level_attributes.push(result);
      setAddonsObj(addonsObj);
      setTemp(!temp);
    }
  };

  const handelChangeDropdownSecondLevel = (
    firstLevelOptionValue,
    event,
    secondLevelOption
  ) => {
    const secondLevelOptionValue = JSON.parse(event);
    const result = {
      options_id: secondLevelOption?.options_id,
      options_values_id: secondLevelOptionValue?.options_values_id,
      options_values_price: secondLevelOptionValue?.options_values_price,
      attributes_max_select: secondLevelOption?.attributes_max_select,
      attributes_min_select: secondLevelOption?.attributes_min_select,
      products_options_name: secondLevelOption?.products_options_name,
      products_options_values_name:
        secondLevelOptionValue?.products_options_values_name,
      quantity: secondLevelOptionValue?.quantity,
      free_modifier_quantity: secondLevelOption?.free_modifier_quantity,
      optionValues1: secondLevelOption?.optionValues,
      attributes_default: secondLevelOptionValue?.attributes_default,
    };
    const newTemp = addonsObj?.attributes?.find(
      (ty) => ty?.options_values_id == firstLevelOptionValue?.options_values_id
    );
    if (
      newTemp?.second_level_attributes?.some(
        (dl) => dl?.options_id == secondLevelOption?.options_id
      )
    ) {
      const pal = newTemp?.second_level_attributes?.filter(
        (el) => el?.options_id !== secondLevelOption?.options_id
      );
      pal.push(result);
      newTemp.second_level_attributes = pal;
      setAddonsObj(addonsObj);
      setTemp(!temp);
    } else {
      newTemp?.second_level_attributes?.push(result);
      setAddonsObj(addonsObj);
      setTemp(!temp);
    }
  };

  const handelChangeTextSecondLevel = (
    firstLevelOptionValue,
    event,
    secondLevelOption,
    secondLevelOptionValue
  ) => {
    setAddonsObj((prevState) => ({
      ...prevState,
      attributes: prevState?.attributes?.map((dy) => {
        if (dy?.options_values_id == firstLevelOptionValue?.options_values_id) {
          return {
            ...dy,
            second_level_attributes: dy?.second_level_attributes?.map((el) => {
              if (
                el?.options_values_id ==
                secondLevelOptionValue?.options_values_id
              ) {
                el.options_value_text = event.target.value;
              }
              return { ...el };
            }),
          };
        } else {
          return { ...dy };
        }
      }),
    }));
    setTemp(!temp);
  };

  const handelChangeQtySecondLevel = (
    event,
    firstLevelOptionValue,
    secondLevelOption,
    secondLevelOptionValue
  ) => {
    if (event == "addition") {
      setAddonsObj((prevState) => ({
        ...prevState,
        attributes: prevState?.attributes?.map((dy) => {
          if (
            dy?.options_values_id == firstLevelOptionValue?.options_values_id
          ) {
            return {
              ...dy,
              second_level_attributes: dy?.second_level_attributes?.map(
                (el) => {
                  if (
                    el?.options_values_id ==
                    secondLevelOptionValue?.options_values_id
                  ) {
                    el.quantity =
                      el.quantity + secondLevelOption.increment_choice_quantity;
                  }
                  return { ...el };
                }
              ),
            };
          } else {
            return { ...dy };
          }
        }),
      }));
    } else {
      setAddonsObj((prevState) => ({
        ...prevState,
        attributes: prevState?.attributes?.map((dy) => {
          if (
            dy?.options_values_id == firstLevelOptionValue?.options_values_id
          ) {
            return {
              ...dy,
              second_level_attributes: dy?.second_level_attributes?.map(
                (el) => {
                  if (
                    el?.options_values_id ==
                    secondLevelOptionValue?.options_values_id
                  ) {
                    el.quantity =
                      el.quantity - secondLevelOption.increment_choice_quantity;
                  }
                  return { ...el };
                }
              ),
            };
          } else {
            return { ...dy };
          }
        }),
      }));
    }
  };

  // const handelDisableCheckboxSecondLevel = (data, optionValue) => {
  const handelDisableCheckboxSecondLevel = (
    firstLevelOptionValue,
    secondLevelOption,
    secondLevelOptionValue
  ) => {
    const defaultOptionValue = addonsObj?.attributes?.find(
      (ty) => ty?.options_values_id == firstLevelOptionValue?.options_values_id
    );
    if (
      secondLevelOption?.attributes_min_select == 1 &&
      secondLevelOption?.attributes_max_select == 1
    ) {
      const newTempId = defaultOptionValue?.second_level_attributes?.find(
        (dy) => dy?.options_id == secondLevelOption?.options_id
      );

      if (newTempId) {
        const newTemp = defaultOptionValue?.second_level_attributes?.find(
          (dy) =>
            dy?.options_id == secondLevelOption?.options_id &&
            dy?.options_values_id == secondLevelOptionValue?.options_values_id
        );

        if (newTemp) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else if (
      secondLevelOption?.attributes_min_select == 0 &&
      secondLevelOption?.attributes_max_select >= 1
    ) {
      const newTempId = defaultOptionValue?.second_level_attributes?.filter(
        (dy) => dy?.options_id == secondLevelOption?.options_id
      );
      if (
        newTempId != undefined &&
        newTempId?.length < secondLevelOption?.attributes_max_select
      ) {
        return false;
      } else {
        const newTempOpt = newTempId?.find(
          (dy) =>
            dy?.options_id == secondLevelOption?.options_id &&
            dy?.options_values_id == secondLevelOptionValue?.options_values_id
        );

        if (newTempOpt) {
          return false;
        } else {
          return true;
        }
      }
    } else if (
      secondLevelOption?.attributes_min_select > 0 &&
      secondLevelOption?.attributes_max_select >= 1
    ) {
      const newTempId = defaultOptionValue?.second_level_attributes?.filter(
        (dy) => dy?.options_id == secondLevelOption?.options_id
      );
      if (
        newTempId != undefined &&
        newTempId?.length < secondLevelOption?.attributes_max_select
      ) {
        return false;
      } else {
        const newTempOpt = newTempId?.find(
          (dy) =>
            dy?.options_id == secondLevelOption?.options_id &&
            dy?.options_values_id == secondLevelOptionValue?.options_values_id
        );

        if (newTempOpt) {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  //********** OLD LOGIC ***********/
  // const renderSecondLevelButtonPriceCount = (firstLevelOptionValue) => {
  //   const newTemp = addonsObj?.attributes?.find(
  //     (ty) => ty?.options_values_id == firstLevelOptionValue?.options_values_id
  //   );

  //   console.log("newTemp", newTemp);

  //   if (newTemp !== undefined && newTemp?.length !== 0) {
  //     let totalPrice = 0;
  //     let optionsIds = [];
  //     let optionPrice = 0;

  //     for (let attributeNested of newTemp?.second_level_attributes) {
  //       if (attributeNested?.free_modifier_quantity) {
  //         const index = optionsIds.findIndex(
  //           (o) =>
  //             o.optionId_Price ===
  //             `${attributeNested.options_id}#${attributeNested?.options_values_price}`
  //         );
  //         if (index !== -1) {
  //           optionsIds[index].quantity += attributeNested?.quantity;
  //         } else {
  //           optionsIds.push({
  //             options_id: attributeNested.options_id,
  //             quantity: attributeNested?.quantity,
  //             optionId_Price: `${attributeNested.options_id}#${attributeNested?.options_values_price}`,
  //             price: attributeNested.options_values_price,
  //           });
  //         }

  //         const isMin =
  //           Math.min(
  //             ...attributeNested?.optionValues1.map((item) =>
  //               parseFloat(item.options_values_price)
  //             )
  //           ) === parseFloat(attributeNested.options_values_price);

  //         if (isMin) {
  //           let optionIndex = optionsIds.findIndex(
  //             (o) =>
  //               o.optionId_Price ===
  //               `${attributeNested.options_id}#${attributeNested?.options_values_price}`
  //           );

  //           console.log(
  //             "attributeNested",
  //             attributeNested?.options_id,
  //             "optionsIds",
  //             optionsIds,
  //             "isMin",
  //             isMin
  //           );

  //           let netQty =
  //             optionsIds[optionIndex]?.quantity -
  //             attributeNested?.free_modifier_quantity;
  //           netQty = netQty > 0 ? netQty : 0;
  //           optionsIds[optionIndex].quantity -= netQty;
  //           optionPrice =
  //             parseFloat(attributeNested?.options_values_price) * netQty;
  //           // console.log("totalPrice1", netQty, totalPrice);
  //         } else {
  //           totalPrice +=
  //             parseFloat(attributeNested?.options_values_price) *
  //             attributeNested?.quantity;
  //           console.log("totalPrice2", totalPrice);
  //         }
  //       } else {
  //         totalPrice +=
  //           parseFloat(attributeNested?.options_values_price) *
  //           attributeNested?.quantity;
  //         console.log("totalPrice3", totalPrice);
  //       }
  //     }
  //     totalPrice += optionPrice;

  //     return (
  //       <span>
  //         <span>{totalPrice ? "$" + totalPrice?.toFixed(2) : "Add"}</span>
  //       </span>
  //     );
  //   }
  // };

  // const handelButtonClick = (firstLevelOptionValue) => {
  //   let selectedOptions = addonsObj?.attributes?.find(
  //     (rr) => rr?.options_values_id == firstLevelOptionValue?.options_values_id
  //   );
  //   const allOptionIds = [];
  //   const allSelectedOptionIds = [];
  //   const selectedArray = [];
  //   selectedOptions?.second_level_attributes?.map((slo) => {
  //     selectedArray.push(slo);
  //   });
  //   firstLevelOptionValue?.second_level_options?.map((slo) => {
  //     if (slo?.attributes_min_select > 0) {
  //       allOptionIds.push(slo.options_id);
  //     }
  //   });
  //   selectedOptions?.second_level_attributes?.map((slo) => {
  //     if (slo?.attributes_min_select > 0) {
  //       allSelectedOptionIds.push(slo.options_id);
  //     }
  //   });
  //   const resultIdsArr = [...allOptionIds, ...allSelectedOptionIds];
  //   let removeIds = removeDuplicates(resultIdsArr);
  //   removeIds = removeIds?.filter((ee) => {
  //     const newTemp = selectedArray?.find((et) => et?.options_id == ee);
  //     if (
  //       !(
  //         newTemp?.attributes_min_select <=
  //         allSelectedOptionIds?.filter((rt) => rt == ee).length
  //       )
  //     ) {
  //       return ee;
  //     }
  //   });
  //   setErrorHandler(removeIds);
  //   setTemp(!temp);
  //   console.log("errorHandlerArr", errorHandlerArr, removeIds);
  //   if (removeIds.length == 0) {
  //     setVisibleSecondLevelBlock(false);
  //   } else {
  //     console.log("divRefs", divRefs);
  //     if (divRefs) {
  //       const targetRef = divRefs.current.find(
  //         (ref) => ref?.id == removeIds[0]
  //       );
  //       if (targetRef) {
  //         targetRef.scrollIntoView({ behavior: "smooth" });
  //       }
  //     }
  //     //errorToast("Please select item");
  //   }
  // };
  //********** OLD LOGIC ***********/

  const renderSecondLevelButtonPriceCount = (firstLevelOptionValue) => {
    const newTemp = addonsObj?.attributes?.find(
      (ty) => ty?.options_values_id == firstLevelOptionValue?.options_values_id
    );

    if (newTemp !== undefined && newTemp?.length !== 0) {
      let totalPrice = 0;
      let optionPrice = 0;

      // const priceGroups = {};

      // for (let attributeNested of newTemp?.second_level_attributes) {
      //     const priceKey = `${attributeNested.options_id}#${attributeNested?.options_values_price}`;

      //     if (!priceGroups[priceKey]) {
      //         priceGroups[priceKey] = [];
      //     }
      //     priceGroups[priceKey].push(attributeNested);
      // }

      const priceGroups = Object.groupBy(
        newTemp?.second_level_attributes,
        ({ options_id, options_values_price }) =>
          `${options_id}#${options_values_price}`
      );

      for (let priceKey in priceGroups) {
        const priceGroup = priceGroups[priceKey];
        let totalQuantity = 0;
        let totalPriceForGroup = 0;
        let freeModifierQuantity = 0;

        for (let attributeNested of priceGroup) {
          totalQuantity += attributeNested?.quantity;
          totalPriceForGroup +=
            parseFloat(attributeNested?.options_values_price) *
            attributeNested?.quantity;
          if (attributeNested?.free_modifier_quantity) {
            freeModifierQuantity = attributeNested?.free_modifier_quantity;
          }
        }

        const isMin =
          Math.min(
            ...Object.keys(priceGroups)?.map((item) => item?.split("#")[1])
          ) === parseFloat(priceGroup?.[0]?.options_values_price);

        if (freeModifierQuantity > 0 && isMin) {
          let netQty = totalQuantity - freeModifierQuantity;
          netQty = netQty > 0 ? netQty : 0;
          totalPriceForGroup =
            parseFloat(priceGroup?.[0]?.options_values_price) * netQty;
          optionPrice += totalPriceForGroup;
        } else {
          totalPrice += totalPriceForGroup;
        }
      }

      totalPrice += optionPrice;

      return (
        <span>
          <span>{totalPrice ? "$" + totalPrice?.toFixed(2) : "Add"}</span>
        </span>
      );
    }
  };

  const handelButtonClick = (firstLevelOptionValue) => {
    let selectedOptions = addonsObj?.attributes?.find(
      (rr) => rr?.options_values_id == firstLevelOptionValue?.options_values_id
    );

    const allOptionIds = [];
    const allSelectedOptionIds = [];
    const selectedArray = [];
    selectedOptions?.second_level_attributes?.map((slo) => {
      selectedArray.push(slo);
    });
    firstLevelOptionValue?.second_level_options?.map((slo) => {
      if (slo?.attributes_min_select > 0) {
        allOptionIds.push(slo.options_id);
      }
    });
    selectedOptions?.second_level_attributes?.map((slo) => {
      if (slo?.attributes_min_select > 0) {
        allSelectedOptionIds.push(slo.options_id);
      }
    });

    const addOn = { ...addonsObj };
    const index = addOn?.attributes?.findIndex(
      (rr) => rr?.options_values_id == firstLevelOptionValue?.options_values_id
    );
    if (index !== -1)
      addOn.attributes[index].second_level_attributes =
        selectedOptions.second_level_attributes?.map((it) => {
          // if (it.attributes_default === 0) {
          // it.isAdd = true;
          // }
          return {
            ...it,
            isAdd: true,
          };
        });
    setAddonsObj(addOn);

    let secondLevelArr = selectedArray?.reduce((accumulator, currentItem) => {
      const index = accumulator.findIndex(
        (item) => item?.options_id == currentItem?.options_id
      );
      if (index !== -1) {
        accumulator[index].quantity += currentItem.quantity;
      } else {
        const option = firstLevelOptionValue?.second_level_options?.find(
          (item) => item?.options_id === currentItem?.options_id
        );
        if (
          option?.min_aggregate_quantity != 0 ||
          option?.max_aggregate_quantity !== 0
        ) {
          accumulator.push({
            options_id: currentItem.options_id,
            quantity: currentItem.quantity,
            min: option?.min_aggregate_quantity,
            max: option?.max_aggregate_quantity,
            product_name: option?.products_options_name,
          });
        }
      }
      return accumulator;
    }, []);
    let obj = { success: false, msg: "" };
    for (let newTemp of secondLevelArr) {
      if (newTemp?.quantity > newTemp?.max) {
        obj = {
          success: true,
          msg: `Maximum ${newTemp?.max} quantity can be choose for ${newTemp?.product_name}`,
        };
        break;
      }
      if (newTemp?.quantity < newTemp?.min) {
        obj = {
          success: true,
          msg: `Minimum ${newTemp?.min} quantity can be choose for ${newTemp?.product_name}`,
        };
        break;
      }
    }

    if (obj?.success) {
      errorToast(obj?.msg);
      return null;
    }

    const resultIdsArr = [...allOptionIds, ...allSelectedOptionIds];
    let removeIds = removeDuplicates(resultIdsArr);
    removeIds = removeIds?.filter((ee) => {
      const newTemp = selectedArray?.find((et) => et?.options_id == ee);
      if (
        !(
          newTemp?.attributes_min_select <=
          allSelectedOptionIds?.filter((rt) => rt == ee).length
        )
      ) {
        return ee;
      }
    });
    setErrorHandler(removeIds);
    setTemp(!temp);
    if (removeIds.length == 0) {
      setVisibleSecondLevelBlock(false);
    } else {
      if (divRefs) {
        const targetRef = divRefs.current.find(
          (ref) => ref?.id == removeIds[0]
        );
        if (targetRef) {
          targetRef.scrollIntoView({ behavior: "smooth" });
        }
      }
      //errorToast("Please select item");
    }
  };

  function removeDuplicates(arr) {
    return [...new Set(arr)];
  }
  const handleBackTrigger = (firstLevelOptionValue) => {
    let selectedOptions = addonsObj?.attributes?.find(
      (rr) => rr?.options_values_id == firstLevelOptionValue?.options_values_id
    );
    const selectedArray = [];
    const allOptionIds = [];
    const allSelectedOptionIds = [];
    firstLevelOptionValue?.second_level_options?.map((slo) => {
      if (slo?.attributes_min_select > 0) {
        allOptionIds.push(slo.options_id);
      }
    });
    selectedOptions?.second_level_attributes?.map((slo) => {
      selectedArray.push(slo);

      if (slo?.attributes_min_select > 0) {
        allSelectedOptionIds.push(slo.options_id);
      }
    });
    const resultIdsArr = [...allOptionIds, ...allSelectedOptionIds];
    let removeIds = removeDuplicates(resultIdsArr);
    removeIds?.map((ee) => {
      const addOn = { ...addonsObj };
      const firstLevel = ADDONS_DETAILS?.find(
        (rr) => rr?.options_id == selectedOptions?.options_id
      )?.optionValues?.find(
        (rr) => rr?.options_values_id == selectedOptions?.options_values_id
      );
      const index = addOn?.attributes?.findIndex(
        (rr) =>
          rr?.options_values_id == firstLevelOptionValue?.options_values_id
      );
      if (index !== -1) {
        const arr = firstLevel.second_level_options
          .map((it) =>
            it.optionValues?.filter((el) => el.attributes_default > 0)
          )
          .flat();
        const selected = selectedOptions?.second_level_attributes?.filter(
          (item) =>
            arr.some(
              (it) =>
                it.options_values_id === item.options_values_id && item?.isAdd
            )
        );
        const valueSelected = selected.length > 0 ? selected : arr;
        addOn.attributes[index].second_level_attributes = [
          ...selectedOptions?.second_level_attributes?.filter(
            (it) => it.isAdd && it.attributes_default === 0
          ),
          ...valueSelected,
        ];
      }

      setAddonsObj(addOn);
    });
    let secondLevelArr = selectedArray?.reduce((accumulator, currentItem) => {
      const index = accumulator.findIndex(
        (item) => item.options_id == currentItem.options_id
      );
      if (index !== -1) {
        accumulator[index].quantity += currentItem.quantity;
      } else {
        const option = firstLevelOptionValue?.second_level_options?.find(
          (item) => item.options_id === currentItem.options_id
        );
        if (
          option?.min_aggregate_quantity != 0 ||
          option?.min_aggregate_quantity != 0
        ) {
          accumulator.push({
            options_id: currentItem.options_id,
            quantity: currentItem.quantity,
            min: option?.min_aggregate_quantity,
            max: option?.max_aggregate_quantity,
            product_name: option?.products_options_name,
          });
        }
      }
      return accumulator;
    }, []);
    let obj = { success: false, msg: "" };
    for (let newTemp of secondLevelArr) {
      if (newTemp?.quantity > newTemp?.max) {
        obj = {
          success: true,
          msg: `Maximum ${newTemp?.max} quantity can be choose for ${newTemp?.product_name}`,
        };
        break;
      }
      if (newTemp?.quantity < newTemp?.min) {
        obj = {
          success: true,
          msg: `Minimum ${newTemp?.min} quantity can be choose for ${newTemp?.product_name}`,
        };
        break;
      }
    }

    if (obj?.success) {
      errorToast(obj?.msg);
      return null;
    }
    setVisibleSecondLevelBlock(false);
  };

  return (
    <div>
      <div className="nesting-modal">
        <h4 className="ps-md-4 ps-3">
          <Button className="bg-transparent border-0 text-dark ps-0">
            <ArrowBackIcon onClick={() => handleBackTrigger(secondLevelData)} />
            {/* <ArrowBackIcon onClick={() => setVisibleSecondLevelBlock(false)} /> */}
          </Button>
          {secondLevelData?.products_options_values_name ?? ""}
        </h4>

        <div className="nesting-wrapper">
          {/* SECOND LEVEL MAIN START */}
          {secondLevelData?.second_level_options &&
            secondLevelData?.second_level_options?.length !== 0 &&
            secondLevelData?.second_level_options?.map((sec, index) => {
              return (
                <Fragment key={sec?.options_id}>
                  {/* CHECKBOX UI START */}
                  {sec?.products_options_type == 3 && (
                    <Fragment>
                      <div className="choose-option mb-2">
                        <div className="choose-option-inner">
                          {" "}
                          <h5
                            id={sec?.options_id}
                            ref={(el) => (divRefs.current[index] = el)}
                          >
                            {sec?.products_options_name}&nbsp;
                            {sec?.free_modifier_quantity > 0 && (
                              <span style={{ fontSize: "13px" }}>
                                ({sec?.free_modifier_quantity} Free option
                                available)
                              </span>
                            )}
                            {errorHandlerArr?.length > 0 &&
                            errorHandlerArr.includes(sec?.options_id) ? (
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "red",
                                  fontWeight: "bold",
                                  marginBottom: "0px",
                                }}
                              >
                                {"(Please select item)"}
                              </span>
                            ) : null}
                          </h5>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "#996F00",
                              fontWeight: "bold",
                            }}
                            className="m-0"
                          >
                            {renderCheckboxRequiredOpt(sec)}
                          </p>
                        </div>

                        {sec?.optionValues &&
                          sec?.optionValues?.length !== 0 &&
                          sec?.optionValues?.map((checkopt, ind) => {
                            return (
                              <FormGroup key={checkopt?.options_values_id}>
                                <div className="nested-wrapper-sec d-flex justify-content-between align-items-center">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        sx={{
                                          color: "",
                                          "&.Mui-checked": {
                                            color: "#F06F00",
                                          },
                                        }}
                                        checked={addonsObj?.attributes
                                          ?.find(
                                            (ft) =>
                                              ft?.options_values_id ==
                                              secondLevelData?.options_values_id
                                          )
                                          ?.second_level_attributes?.some(
                                            (el) =>
                                              el?.options_values_id ==
                                              checkopt?.options_values_id
                                                ? true
                                                : false
                                          )}
                                        onChange={(e) =>
                                          handelChangeCheckbox(
                                            e.target.checked,
                                            secondLevelData,
                                            sec,
                                            checkopt
                                          )
                                        }
                                        disabled={handelDisableCheckboxSecondLevel(
                                          secondLevelData,
                                          sec,
                                          checkopt
                                        )}
                                      />
                                    }
                                    // label={checkopt?.products_options_values_name}
                                    label={
                                      <>
                                        <div className="d-flex justify-content-between w-100 salad-wrapper">
                                          <span>
                                            {
                                              checkopt?.products_options_values_name
                                            }
                                          </span>
                                        </div>
                                        {RESTAURANTS?.is_display_modifier_quantity ===
                                          1 &&
                                          parseFloat(
                                            checkopt?.options_values_price
                                          )?.toFixed(2) > 0 && (
                                            <span className="price fw-semibold">
                                              $
                                              {parseFloat(
                                                checkopt?.options_values_price
                                              )?.toFixed(2)}
                                            </span>
                                          )}
                                      </>
                                    }
                                    className="w-100"
                                  />
                                  <div className="d-flex align-items-center nested-price-wrapper">
                                    {/* <span className="price">
                                      $
                                      {parseFloat(
                                        checkopt?.options_values_price
                                      )?.toFixed(2)}
                                    </span> */}

                                    {RESTAURANTS?.is_display_modifier_quantity ===
                                      1 && sec.support_choice_quantities ? (
                                      <div className="qty-wrap">
                                        <button
                                          type="button"
                                          disabled={
                                            addonsObj?.attributes
                                              ?.find(
                                                (ft) =>
                                                  ft?.options_values_id ==
                                                  secondLevelData?.options_values_id
                                              )
                                              ?.second_level_attributes?.some(
                                                (el) =>
                                                  el?.options_values_id ==
                                                  checkopt?.options_values_id
                                              )
                                              ? false
                                              : true
                                          }
                                          className={
                                            addonsObj?.attributes
                                              ?.find(
                                                (ft) =>
                                                  ft?.options_values_id ==
                                                  secondLevelData?.options_values_id
                                              )
                                              ?.second_level_attributes?.some(
                                                (el) =>
                                                  el?.options_values_id ==
                                                  checkopt?.options_values_id
                                              )
                                              ? ""
                                              : "qty-button-disable"
                                          }
                                          onClick={() => {
                                            if (
                                              addonsObj?.attributes
                                                ?.find(
                                                  (ft) =>
                                                    ft?.options_values_id ==
                                                    secondLevelData?.options_values_id
                                                )
                                                ?.second_level_attributes?.find(
                                                  (el) =>
                                                    el?.options_values_id ==
                                                    checkopt?.options_values_id
                                                )?.quantity >
                                              checkopt.min_choice_quantity
                                            ) {
                                              handelChangeQtySecondLevel(
                                                "subtract",
                                                secondLevelData,
                                                sec,
                                                checkopt
                                              );
                                            }
                                          }}
                                        >
                                          <RemoveCircleOutlineOutlinedIcon />
                                        </button>

                                        <span
                                          className={
                                            addonsObj?.attributes
                                              ?.find(
                                                (ft) =>
                                                  ft?.options_values_id ==
                                                  secondLevelData?.options_values_id
                                              )
                                              ?.second_level_attributes?.some(
                                                (el) =>
                                                  el?.options_values_id ==
                                                  checkopt?.options_values_id
                                              )
                                              ? "qty-count"
                                              : "qty-count qty-disable"
                                          }
                                        >
                                          {addonsObj?.attributes
                                            ?.find(
                                              (ft) =>
                                                ft?.options_values_id ==
                                                secondLevelData?.options_values_id
                                            )
                                            ?.second_level_attributes?.find(
                                              (el) =>
                                                el?.options_values_id ==
                                                checkopt?.options_values_id
                                            )?.quantity ?? 0}
                                        </span>
                                        <button
                                          type="button"
                                          disabled={
                                            addonsObj?.attributes
                                              ?.find(
                                                (ft) =>
                                                  ft?.options_values_id ==
                                                  secondLevelData?.options_values_id
                                              )
                                              ?.second_level_attributes?.some(
                                                (el) =>
                                                  el?.options_values_id ==
                                                  checkopt?.options_values_id
                                              )
                                              ? false
                                              : true
                                          }
                                          className={
                                            addonsObj?.attributes
                                              ?.find(
                                                (ft) =>
                                                  ft?.options_values_id ==
                                                  secondLevelData?.options_values_id
                                              )
                                              ?.second_level_attributes?.some(
                                                (el) =>
                                                  el?.options_values_id ==
                                                  checkopt?.options_values_id
                                              )
                                              ? ""
                                              : "qty-button-disable"
                                          }
                                          onClick={() => {
                                            // const maxAggregate =
                                            //   addonsObj?.attributes?.find(
                                            //     (ft) =>
                                            //       ft?.options_values_id ==
                                            //       secondLevelData?.options_values_id
                                            //   )?.second_level_attributes;

                                            // let totalQuantity =
                                            //   maxAggregate.reduce(
                                            //     (accumulator, currentItem) => {
                                            //       return (
                                            //         accumulator +
                                            //         currentItem.quantity
                                            //       );
                                            //     },
                                            //     0
                                            //   );
                                            // if (
                                            //   addonsObj?.attributes
                                            //     ?.find(
                                            //       (ft) =>
                                            //         ft?.options_values_id ==
                                            //         secondLevelData?.options_values_id
                                            //     )
                                            //     ?.second_level_attributes?.find(
                                            //       (el) =>
                                            //         el?.options_values_id ==
                                            //         checkopt?.options_values_id
                                            //     )?.quantity <
                                            //   checkopt.max_choice_quantity
                                            // ) {
                                            //   totalQuantity += 1;
                                            // }
                                            // &&
                                            // totalQuantity <=
                                            //   sec?.max_aggregate_quantity
                                            if (
                                              checkopt.max_choice_quantity ===
                                                0 ||
                                              addonsObj?.attributes
                                                ?.find(
                                                  (ft) =>
                                                    ft?.options_values_id ==
                                                    secondLevelData?.options_values_id
                                                )
                                                ?.second_level_attributes?.find(
                                                  (el) =>
                                                    el?.options_values_id ==
                                                    checkopt?.options_values_id
                                                )?.quantity <
                                                checkopt.max_choice_quantity
                                            ) {
                                              handelChangeQtySecondLevel(
                                                "addition",
                                                secondLevelData,
                                                sec,
                                                checkopt
                                              );
                                            }
                                          }}
                                        >
                                          <ControlPointOutlinedIcon />
                                        </button>
                                      </div>
                                    ) : (
                                      <>
                                        {parseFloat(
                                          checkopt?.options_values_price
                                        )?.toFixed(2) > 0 ? (
                                          <span className="price">
                                            $
                                            {parseFloat(
                                              checkopt?.options_values_price
                                            )?.toFixed(2)}
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </FormGroup>
                            );
                          })}
                      </div>
                    </Fragment>
                  )}
                  {/* CHECKBOX UI END */}

                  {/* RADIO UI START */}
                  {sec?.products_options_type == 2 && (
                    <div className="choose-option mb-2">
                      <div className="choose-option-inner 123">
                        {" "}
                        <h5
                          id={sec?.options_id}
                          ref={(el) => (divRefs.current[index] = el)}
                        >
                          {sec?.products_options_name}{" "}
                          {sec?.free_modifier_quantity > 0 && (
                            <span style={{ fontSize: "13px" }}>
                              ({sec?.free_modifier_quantity} Free option
                              available)
                            </span>
                          )}
                          {errorHandlerArr?.length > 0 &&
                          errorHandlerArr.includes(sec?.options_id) ? (
                            <span
                              style={{
                                fontSize: "14px",
                                color: "red",
                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {"(Please select item)"}
                            </span>
                          ) : null}
                          <p
                            style={{
                              fontSize: "14px",
                              color: "#996F00",
                              fontWeight: "bold",
                            }}
                            className="m-0"
                          >
                            {renderCheckboxRequiredOpt(sec)}
                          </p>
                        </h5>
                        {/* <span>Choose up to 4</span> */}
                      </div>

                      {/* <FormControl> */}
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        // defaultValue=""

                        // name="radio-buttons-group"
                        onChange={(e) =>
                          handelChangeRadioSecondLevel(
                            secondLevelData,
                            e.target.value,
                            sec
                          )
                        }
                      >
                        {sec?.optionValues &&
                          sec?.optionValues?.length !== 0 &&
                          sec?.optionValues?.map((radioOpt) => {
                            return (
                              <div
                                className="nested-wrapper-sec d-flex justify-content-between align-items-center"
                                key={radioOpt?.options_values_id}
                              >
                                <FormControlLabel
                                  value={JSON.stringify(radioOpt)}
                                  control={
                                    <Radio
                                      checked={addonsObj?.attributes
                                        ?.find(
                                          (ft) =>
                                            ft?.options_values_id ==
                                            secondLevelData?.options_values_id
                                        )
                                        ?.second_level_attributes?.some((el) =>
                                          el?.options_values_id ==
                                          radioOpt?.options_values_id
                                            ? true
                                            : false
                                        )}
                                      sx={{
                                        color: "",
                                        "&.Mui-checked": {
                                          color: "#F06F00",
                                        },
                                      }}
                                    />
                                  }
                                  // label={radioOpt?.products_options_values_name}
                                  label={
                                    <>
                                      <div className="d-flex justify-content-between w-100 salad-wrapper">
                                        <span>
                                          {
                                            radioOpt?.products_options_values_name
                                          }
                                        </span>
                                      </div>
                                      {RESTAURANTS?.is_display_modifier_quantity ===
                                        1 &&
                                        parseFloat(
                                          radioOpt?.options_values_price
                                        )?.toFixed(2) > 0 && (
                                          <span className="price">
                                            $
                                            {parseFloat(
                                              radioOpt?.options_values_price
                                            )?.toFixed(2)}
                                          </span>
                                        )}
                                    </>
                                  }
                                />
                                <div className="d-flex align-items-center nested-price-wrapper">
                                  {/* <span className="price">
                                    $
                                    {parseFloat(
                                      radioOpt?.options_values_price
                                    )?.toFixed(2)}
                                  </span> */}
                                  {RESTAURANTS?.is_display_modifier_quantity ===
                                    1 && sec.support_choice_quantities ? (
                                    <div className="qty-wrap">
                                      <button
                                        type="button"
                                        disabled={
                                          addonsObj?.attributes
                                            ?.find(
                                              (ft) =>
                                                ft?.options_values_id ==
                                                secondLevelData?.options_values_id
                                            )
                                            ?.second_level_attributes?.some(
                                              (el) =>
                                                el?.options_values_id ==
                                                radioOpt?.options_values_id
                                            )
                                            ? false
                                            : true
                                        }
                                        className={
                                          addonsObj?.attributes
                                            ?.find(
                                              (ft) =>
                                                ft?.options_values_id ==
                                                secondLevelData?.options_values_id
                                            )
                                            ?.second_level_attributes?.some(
                                              (el) =>
                                                el?.options_values_id ==
                                                radioOpt?.options_values_id
                                            )
                                            ? ""
                                            : "qty-button-disable"
                                        }
                                        onClick={() => {
                                          if (
                                            addonsObj?.attributes
                                              ?.find(
                                                (ft) =>
                                                  ft?.options_values_id ==
                                                  secondLevelData?.options_values_id
                                              )
                                              ?.second_level_attributes?.find(
                                                (el) =>
                                                  el?.options_values_id ==
                                                  radioOpt?.options_values_id
                                              )?.quantity >
                                            radioOpt.min_choice_quantity
                                          ) {
                                            handelChangeQtySecondLevel(
                                              "subtract",
                                              secondLevelData,
                                              sec,
                                              radioOpt
                                            );
                                          }
                                        }}
                                      >
                                        <RemoveCircleOutlineOutlinedIcon />
                                      </button>

                                      <span
                                        className={
                                          addonsObj?.attributes
                                            ?.find(
                                              (ft) =>
                                                ft?.options_values_id ==
                                                secondLevelData?.options_values_id
                                            )
                                            ?.second_level_attributes?.some(
                                              (el) =>
                                                el?.options_values_id ==
                                                radioOpt?.options_values_id
                                            )
                                            ? "qty-count"
                                            : "qty-count qty-disable"
                                        }
                                      >
                                        {addonsObj?.attributes
                                          ?.find(
                                            (ft) =>
                                              ft?.options_values_id ==
                                              secondLevelData?.options_values_id
                                          )
                                          ?.second_level_attributes?.find(
                                            (el) =>
                                              el?.options_values_id ==
                                              radioOpt?.options_values_id
                                          )?.quantity ?? 0}
                                      </span>
                                      <button
                                        type="button"
                                        disabled={
                                          addonsObj?.attributes
                                            ?.find(
                                              (ft) =>
                                                ft?.options_values_id ==
                                                secondLevelData?.options_values_id
                                            )
                                            ?.second_level_attributes?.some(
                                              (el) =>
                                                el?.options_values_id ==
                                                radioOpt?.options_values_id
                                            )
                                            ? false
                                            : true
                                        }
                                        className={
                                          addonsObj?.attributes
                                            ?.find(
                                              (ft) =>
                                                ft?.options_values_id ==
                                                secondLevelData?.options_values_id
                                            )
                                            ?.second_level_attributes?.some(
                                              (el) =>
                                                el?.options_values_id ==
                                                radioOpt?.options_values_id
                                            )
                                            ? ""
                                            : "qty-button-disable"
                                        }
                                        onClick={() => {
                                          if (
                                            radioOpt.max_choice_quantity ===
                                              0 ||
                                            addonsObj?.attributes
                                              ?.find(
                                                (ft) =>
                                                  ft?.options_values_id ==
                                                  secondLevelData?.options_values_id
                                              )
                                              ?.second_level_attributes?.find(
                                                (el) =>
                                                  el?.options_values_id ==
                                                  radioOpt?.options_values_id
                                              )?.quantity <
                                              radioOpt.max_choice_quantity
                                          ) {
                                            handelChangeQtySecondLevel(
                                              "addition",
                                              secondLevelData,
                                              sec,
                                              radioOpt
                                            );
                                          }
                                        }}
                                      >
                                        <ControlPointOutlinedIcon />
                                      </button>
                                    </div>
                                  ) : (
                                    <>
                                      {parseFloat(
                                        radioOpt?.options_values_price
                                      )?.toFixed(2) > 0 ? (
                                        <span className="price">
                                          $
                                          {parseFloat(
                                            radioOpt?.options_values_price
                                          )?.toFixed(2)}
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </RadioGroup>
                      {/* </FormControl> */}
                    </div>
                  )}
                  {/* RADIO UI START */}

                  {/* TEXT UI START */}
                  {sec?.products_options_type == 1 && (
                    <div className="choose-option mb-2">
                      <div className="choose-option-inner">
                        {" "}
                        <h5
                          id={sec?.options_id}
                          ref={(el) => (divRefs.current[index] = el)}
                        >
                          {sec?.products_options_name}{" "}
                          {errorHandlerArr?.length > 0 &&
                          errorHandlerArr.includes(sec?.options_id) ? (
                            <span
                              style={{
                                fontSize: "14px",
                                color: "red",
                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {"(Please select item)"}
                            </span>
                          ) : null}
                        </h5>
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#000",
                            marginBottom: "0px",
                          }}
                        >
                          (additional charges may apply)
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#996F00",
                            fontWeight: "bold",
                          }}
                          className="m-0"
                        >
                          {renderCheckboxRequiredOpt(sec)}
                        </p>
                        {/* <span>Choose up to 4</span> */}
                      </div>

                      {sec?.optionValues &&
                        sec?.optionValues?.length !== 0 &&
                        sec?.optionValues?.map((textOpt) => {
                          return (
                            <Fragment key={textOpt?.options_values_id}>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                className="my-3"
                                placeholder="Add Special instructions"
                                style={{ resize: "none" }}
                                onChange={(e) =>
                                  handelChangeTextSecondLevel(
                                    secondLevelData,
                                    e,
                                    sec,
                                    textOpt
                                  )
                                }
                                value={
                                  addonsObj?.attributes
                                    ?.find(
                                      (ft) =>
                                        ft?.options_values_id ==
                                        secondLevelData?.options_values_id
                                    )
                                    ?.second_level_attributes?.find(
                                      (el) =>
                                        el?.options_values_id ==
                                        textOpt?.options_values_id
                                    )?.options_value_text
                                }
                              />
                            </Fragment>
                          );
                        })}
                    </div>
                  )}
                  {/* TEXT UI END  */}

                  {/* DROPDOWN UI START */}
                  {sec?.products_options_type == 0 && (
                    <div className="choose-option mb-2 build-own-li">
                      <div className="choose-option-inner">
                        {" "}
                        <h5
                          id={sec?.options_id}
                          ref={(el) => (divRefs.current[index] = el)}
                        >
                          {sec?.products_options_name}{" "}
                          {errorHandlerArr?.length > 0 &&
                          errorHandlerArr.includes(sec?.options_id) ? (
                            <span
                              style={{
                                fontSize: "14px",
                                color: "red",
                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {"(Please select item)"}
                            </span>
                          ) : null}
                        </h5>
                        <p
                          style={{
                            fontSize: "14px",
                            color: "#996F00",
                            fontWeight: "bold",
                          }}
                          className="m-0"
                        >
                          {renderCheckboxRequiredOpt(sec)}
                        </p>
                        {/* <span>Choose up to 4</span> */}
                      </div>

                      <div className="product-data-sec">
                        <DropdownButton
                          className="form-control p-0"
                          title={
                            <h6 className="d-flex justify-content-between w-100 pe-3 mb-0 h-100 align-items-center ">
                              {/* <span>test</span> <span>$000</span> */}
                              {renderDropdownTitleSecondLevel(
                                secondLevelData,
                                sec,
                                addonsObj
                              )}
                            </h6>
                          }
                          id="dropdown-menu-align-right"
                          onSelect={(event) =>
                            handelChangeDropdownSecondLevel(
                              secondLevelData,
                              event,
                              sec
                            )
                          }
                        >
                          {sec?.optionValues &&
                            sec?.optionValues?.length !== 0 &&
                            sec?.optionValues?.map((dropOpt) => {
                              return (
                                <Fragment key={dropOpt?.products_attributes_id}>
                                  <Dropdown.Item
                                    eventKey={JSON.stringify(dropOpt)}
                                    active={addonsObj?.attributes
                                      ?.find(
                                        (ft) =>
                                          ft?.options_values_id ==
                                          secondLevelData?.options_values_id
                                      )
                                      ?.second_level_attributes?.some((el) =>
                                        el?.options_values_id ==
                                        dropOpt?.options_values_id
                                          ? true
                                          : false
                                      )}
                                  >
                                    {dropOpt?.products_options_values_name}
                                    {parseFloat(
                                      dropOpt?.options_values_price
                                    )?.toFixed(2) > 0 &&
                                      dropOpt?.options_values_price && (
                                        <span
                                          style={{
                                            float: "right",
                                          }}
                                        >
                                          $
                                          {parseFloat(
                                            dropOpt?.options_values_price
                                          )?.toFixed(2)}
                                        </span>
                                      )}
                                  </Dropdown.Item>
                                </Fragment>
                              );
                            })}
                        </DropdownButton>
                      </div>
                    </div>
                  )}
                  {/* DROPDOWN UI END */}
                </Fragment>
              );
            })}
          {/* SECOND LEVEL MAIN END */}
        </div>

        <div className="nesting-footer place-order-btn">
          <Button
            className="common-btn w-100 add-to-order"
            type="button"
            // onClick={() => secondLevelSubmitButton(secondLevelData)}
            onClick={() => handelButtonClick(secondLevelData)}
          >
            {renderSecondLevelButtonPriceCount(secondLevelData)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SecondLevelBlock;
